export default [
  {
    key: 'increasement',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'number',
    label: 'field.number',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'lotteryName',
    label: 'field.lotteryName',
  },
  {
    key: 'lotteryTime',
    label: 'field.lotteryTime',
  },
  {
    key: 'post',
    label: 'field.channel',
  },
  {
    key: 'date',
    label: 'field.blockDate',
  },
  {
    key: 'actions',
    label: 'field.actions',
  },
];
